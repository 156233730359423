.venteflashCounter{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width:100vw;
    top: -45px;
    background-color: #ffeb3b;
    font-size: 14px;
    letter-spacing: 2px;
    font-family: 'Poppins';
    text-transform: uppercase;
}
.venteflashCounter>h3{
margin-right: 10px;

}
.flashImgHome{
    width: 100vw;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
}

.flashContain{
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
    justify-content: center;
    width: 95vw;
    margin-top: 50px;
    
}
.flashImgContainer{
    width: 20vw;
    height: auto;
   
    
}
.images-zoom-container-flash{
    width: 20vw;
    height: auto;
    margin-bottom: 50px;
   
  }
  .images-zoom-container-flash{
    transition: transform 0.5s;
  }
  .images-zoom-container-flash:hover {
    transform: scale(1.04);
  }
.flashImg{
    width: 280px;
    box-shadow: 0px 1px 12px 1px rgba(104, 104, 104, 0.25);
}
@media screen and (max-width: 1200px) {
    .venteflashCounter{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100vw;
        margin-left: 0vw;
        top: -45px;
        font-size: 12px;
        letter-spacing: 2px;
    }
    .venteflashCounter>h3{
    margin-right: 10px;
    }
    .flashImgHome{
        width: 100vw;
        margin-left: 0vw;
        height: auto;
        border-radius: 4px;
        cursor: pointer;
      }
  
    .flashContain{
        width: 95vw;
        margin-left: 2.5vw;
        gap: 25px;
        justify-content: center;
        align-items: center;
    }
    .flashImgContainer{
        width: 220px;
        height: auto;
    }
    .images-zoom-container-flash{
        width: 220px;
        height: auto;
        margin-bottom: 25px;
      }
    .flashImg{
        width: 220px;
    }
}
@media screen and (max-width: 700px) {
 
.venteflashCounter {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top: -45px;
    font-size: 10px;
    letter-spacing: 2px;
}

.flashContain{
    width: 95vw;
    margin-left: 2.5vw;
  
    gap: 20px;
    justify-content: center;
    align-items: center;
}
.flashImgContainer{
    width: 150px;
    height: auto;
}
.images-zoom-container-flash{
    width: 150px;
    height: auto;
    margin-bottom: 15px;
  }
.flashImg{
    width: 150px;
}
}
