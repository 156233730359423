.footer{
    display: flex;
    flex-direction: column;
    margin-top: 150px;
   
}
.footerBottom{
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    margin: 0;
    height: auto;
    background-color:#293843;
}
.min{
    display: none;
}
.footPic{
    width: 100vw;
    height: auto;
}
.foot{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    color: white;
    font-family: 'Exo';
    height: 70%;
    width: 25%;
}
.footMiddle{
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    color: white;
    height: 70%;
    width: 35%;
}

.foot>span{
    text-align: center;
}
.foot>h3{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
}
.foot>h3>a{
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: white;
    text-decoration: none;
}

.foot>span{
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1px;
}
.foot>Button{
 font-family: 'Poppins';
}
.logoFoot{
    width: 180px;
    height: auto;
}
.socialFoot{
    width: 60%;
    margin-left: 20%;
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.socialFootPower{
    margin-top: 50px;
    font-family: 'Exo';
    word-spacing: 4px;
}
.socialFootPower>a{
    text-decoration: none;
 color: var(--secondColor);}
.socialFoot>a{
 text-decoration: none;
 color: aliceblue;
}

@media only screen and (max-width: 1200px) {
    .foot{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        color: white;
        font-family: 'Exo';
        height: 80%;
        width: 35%;
    }
    .footMiddle{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        color: white;
        height: 70%;
        width: 30%;
    }
    
    .foot>span{
        text-align: center;
    }
    .foot>h3{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
    }
    .foot>h3>a{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
        text-decoration: none;
    }
    
    .foot>span{
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;
    }
    .foot>Button{
     font-family: 'Poppins';
    }
    .logoFoot{
        width: 140px;
        height: auto;
    }
    .socialFoot{
        width: 60%;
        margin-left: 20%;
        margin-top: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .socialFoot>a{
     text-decoration: none;
     color: aliceblue;
    }
    
}
@media only screen and (max-width: 700px) {
    .footer{
        margin-top: 100px;
       
    }
    .foot{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        color: white;
        font-family: 'Exo';
        width: 90%;
    }
    .min{
        display: block;
    }
    .max{
        display: none;
    }
    .footerBottom{
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        bottom: 0;
        margin: 0;
        height: auto;
        background-color:#293843;
    }
    .footMiddle{
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        color: white;
        height: 70%;
        width: 80%;
        margin-bottom: 5px;
    }
    
    .foot>span{
        text-align: center;
    }
    .foot>h3{
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
    }
    .foot>h3>a{
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
        color: white;
        text-decoration: none;
    }

    
    .foot>span{
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1px;
    }
    .foot>Button{
     font-family: 'Poppins';
    }
    .logoFoot{
        margin-top: 20px;
        width: 100px;
        height: auto;
    }
    .socialFoot{
        width: 60%;
        margin-left: 20%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .socialFoot>a{
     text-decoration: none;
     color: aliceblue;
    }
    
}