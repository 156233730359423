
.productMarqueContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-left: 2.5%;
  height: auto;
  margin-top: -60px;
}

.productLogo{
  width: 220px;
  height: auto;
  margin-top: 0px;
 
}


@media screen and (max-width: 1200px) {
  

}
@media screen and (max-width: 700px) {
 
.productMarqueContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  margin-top: -60px;
}

.productLogo{
  width: 160px;
  height: auto;
  margin-top: -5px;
}
}
