.hidden {
  display: none; 
}
.cardBest:hover{
  transform: scale(1.03);
}

.cardBest {
  position: relative;
  margin-bottom: 15px;
  margin:0px 2px;
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}
.cardMediaLink{
  text-decoration: none;
  text-align: center;
}
.BestcardMedia{
  margin-left: 20%;
width: 60%;
height: auto;
transition: transform 0.4s ease-in-out;
cursor: pointer;
}

.BestcardMedia:hover {
transform: scale(1.1);
color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
padding: 50px;
width: 80%;
height: auto;
transition: transform 0.4s ease-in-out;
cursor: pointer;
}

.cardMediaDialog:hover {
transform: scale(1.1);
}
.articleDetail{
font-family: 'Poppins';
font-size: 13px;
margin-top: 5px;
}
@media screen and (max-width:1200px) {
  .cardBest {
    position: relative;
    margin-bottom: 15px;
    margin:0px 2px;
    transition: transform 0.2s ease-in-out;
  cursor: pointer;
  }
  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
  .BestcardMedia{
  width: 60%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  }
  
  .BestcardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  }
  
  .cardMediaDialog:hover {
  transform: scale(1.1);
  }
  .articleDetail{
  font-family: 'Poppins';
  font-size: 13px;
  margin-top: 5px;
  }
}  
@media screen and (max-width:700px) {
  .cardBest {
    position: relative;
    margin-bottom: 15px;
    margin:0px 2px;
    transition: transform 0.2s ease-in-out;
  cursor: pointer;
  }
  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
  .BestcardMedia{
  width: 60%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  }
  
  .BestcardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
  }
  
  .cardMediaDialog:hover {
  transform: scale(1.1);
  }
  .articleDetail{
  font-family: 'Poppins';
  font-size: 12px;
  margin-top: 5px;
  }
}  