.banniereNous{
    width: 100vw;
    height: auto;
}
.sommeNousDiv{
  width: 90vw;
  margin-left: 5vw;
  margin-top: 50px;
}
.sommeNousDiv>h3{
  font-family: 'Poppins';
}
.sommeNousDiv>p{
  font-family: 'Exo';
  padding-left: 1vw;
  line-height: 1.5rem;
}
@media only screen and (max-width: 700px) {
 
.sommeNousDiv{
  width: 90vw;
  margin-left: 5vw;
  margin-top: 50px;
}
.sommeNousDiv>h3{
  font-family: 'Poppins';
  font-size: 14px;
}
.sommeNousDiv>p{
  font-family: 'Exo';
  font-size: 12px;
 padding-left: 5px;
  line-height: 1.5rem;
}
}