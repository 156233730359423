.bestProduct {
  width: 100vw;
  margin: 30px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-content: center;
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 700px) {
  .bestProduct {
    width: 90vw;
    margin: 20px -2px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    align-self: center;
    
  }
}
