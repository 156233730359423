
.homeContainer {
  width: 90vw;
  margin-left: 5vw;
  margin-top: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  z-index: 99;
  animation: load 2s ease-in-out;
}
.bandHome {
  width: 60%;
  margin-left: 20%;
}

.custom-dialog {
  z-index: 99999999999999999999999999999999999999999999999999;
  background-image: url("../../images/homePromo2.jpeg");
  width: 400px;
  height: 400px;
  background-size: cover;
}
.dialogPromoTitle {
  font-family: "Poppins";
  font-size: 14px;
}
.bigTitle {
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  background-color: #283943;
  color: #f0e7d8;
  text-align: center;
}
.bigTitleSlide {
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  color: #283943;
  background-color: #fff;
  width: 20%;
  margin-left: 40%;
  text-align: center;
}
.bigTitleSlide2 {
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 25px;
  margin-bottom: 20px;
  font-family: "Poppins";
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  width: 20%;
  margin-left: 40%;
  background-color: #283943;
  color: #f0e7d8;
  text-align: center;
}
.categorie {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.categDiv {
  margin-top: 25px;
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.flashHome {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}
.flashImgHome {
  width: 46%;
}
.cardCateg {
  position: relative;
  width: 20vw;
  cursor: pointer;
}
.catButton {
  color: white;
  transition: color 0.8s ease;
}

.catButton:hover {
  color: #283943;
}

#card-img {
  width: 100%;
  border-radius: 4px;
}

.categDiv2 {
  width: 90%;
  margin: 100px 5% 10px 5%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.catImg {
  width: 50%;
  transition: all 0.6s ease;
}

.categDivAnimation {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: auto;
  cursor: pointer;
  font-family: "exo";
  text-align: center;
}
.categDivAnimation > a {
  color: black;
  text-decoration: none;
}

.categDivAnimation > h2 {
  font-size: 18px;
  text-transform: uppercase;
}

.categDivAnimation.hovered > h2 {
  font-size: 16px;
}

.categDivAnimation.hovered {
  transform: translateY(-20px);
}

.categDivAnimation p {
  display: none;
  opacity: 0;
}

.categDivAnimation.hovered p {
  display: block;
  opacity: 1;
  margin-top: 40px;
}

.categDivAnimation .catImg {
  width: 100%;
  transition: all 0.6s ease;
}

.categDivAnimation.hovered .catImg {
  width: 80%;
}
@keyframes myAnim {
  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}


.promoGridHome {
  margin-top: 40px;
 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}
.promoImgHome {
  width: 20vw;
}
.catHome{
  margin-top: 40px;
  margin-bottom: 50px;
  width: 95%;
  margin-left: 2.5%;
}
.catImgHome{
  width: 18vw;
}
.images-zoom-container-home {
  width: 20vw;
  height: auto;
}
.images-zoom-container-home {
  transition: transform 0.5s;
}
.images-zoom-container-home:hover {
  transform: scale(1.04);
}
.categSlider {
  margin-top: 20px;
 
}
@media only screen and (max-width: 1200px) {
  .homeContainer {
    width: 90vw;
    margin-left: 5vw;
    margin-top: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    z-index: 99;
    animation: load 2s ease-in-out;
  }

  .bigTitle {
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .bigTitleSlide {
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 30%;
    margin-left: 35%;
  }
  .bigTitleSlide2 {
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 14px;
    width: 40%;
    margin-left: 30%;
  }
  .categorie {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .categDiv {
    margin-top: 25px;
    margin-left: 2.5%;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }

  .cardCateg {
    position: relative;
    width: 25vw;
    cursor: pointer;
  }

  #card-img {
    width: 90%;
    border-radius: 4px;
  }

  .categDiv2 {
    width: 90%;
    margin: 100px 5% 20px 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .catImg {
    width: 80%;
    transition: all 0.6s ease;
  }

  .categDivAnimation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: auto;
    cursor: pointer;
    font-family: "exo";
    text-align: center;
  }
  .categDivAnimation > a {
    color: black;
    text-decoration: none;
  }
  .categDivAnimation > h2 {
    font-size: 14px;
    margin-left: 5px;
    text-transform: uppercase;
  }

  .categDivAnimation.hovered > h2 {
    font-size: 14px;
  }

  .categDivAnimation.hovered {
    transform: translateY(-20px);
  }

  .categDivAnimation p {
    display: none;
    opacity: 0;
    font-size: 12px;
  }

  .categDivAnimation.hovered p {
    display: block;
    opacity: 1;
    margin-top: 40px;
    font-size: 12px;
  }

  .categDivAnimation .catImg {
    width: 100%;
    transition: all 0.6s ease;
  }

  .categDivAnimation.hovered .catImg {
    width: 80%;
  }

  @keyframes myAnim {
    0%,
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }
  .carouselDivRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .carouselDivRight > p {
    margin-top: -20px;
    width: 70%;
    text-align: center;
    font-family: "Exo";
    font-size: 16px;
    margin-bottom: 40px;
  }
  .promoGridHome {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .promoImgHome {
    width: 35vw;
  }
  .catImgHome {
    width: 22vw;
  }
  .catHome{
    margin-top: 40px;
    margin-bottom: 50px;
    width: 95%;
    margin-left: 2.5%;
  }
  .images-zoom-container-home {
    width: 40vw;
    height: auto;
  }
  .images-zoom-container-home {
    transition: transform 0.5s;
  }
  .images-zoom-container-home:hover {
    transform: scale(1.04);
  }
}
@media only screen and (max-width: 700px) {
  .homeContainer {
    width: 95vw;
    margin-left: 2.5vw;
    margin-top: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    position: relative;
    z-index: 99;
    animation: load 2s ease-in-out;
  }
  .custom-dialog {
    width: 250px;
    height: 250px;
  }

  .bigTitle {
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 12px;
  }
  .bigTitleSlide {
    padding: 2px 4px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 12px;
    width: 50%;
    margin-left: 25%;
  }
  .bigTitleSlide2 {
    padding: 2px 4px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 12px;
    width: 60%;
    margin-left: 20%;
  }
  .categorie {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }

  .categDiv {
    margin-top: 5px;
    margin-left: 2.5%;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto auto auto;
    align-items: center;
    justify-content: space-evenly;
  }

  .cardCateg {
    margin-top: 10px;
    position: relative;
    width: 25vw;
    cursor: pointer;
  }

  #card-img {
    width: 90%;
    border-radius: 4px;
  }

  .categDiv2 {
    width: 100%;
    margin: 60px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

  .catImg {
    width: 60%;
    transition: all 0.6s ease;
  }

  .categDivAnimation {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    width: 70vw;
    height: auto;
    cursor: pointer;
    font-family: "exo";
    text-align: center;
  }
  .categDivAnimation > a {
    color: black;
    text-decoration: none;
  }
  .categDivAnimation > h2 {
    font-size: 10px;
    margin-left: 5px;
    text-transform: uppercase;
  }

  .categDivAnimation.hovered > h2 {
    font-size: 12px;
  }

  .categDivAnimation.hovered {
    transform: translateY(-20px);
  }

  .categDivAnimation p {
    display: none;
    opacity: 0;
    font-size: 10px;
  }

  .categDivAnimation.hovered p {
    display: block;
    opacity: 1;
    margin-top: 40px;
    font-size: 12px;
  }

  .categDivAnimation .catImg {
    width: 80%;
    margin-left: 10%;
    transition: all 0.6s ease;
  }

  .categDivAnimation.hovered .catImg {
    width: 80%;
  }

  @keyframes myAnim {
    0%,
    50%,
    100% {
      opacity: 1;
    }

    25%,
    75% {
      opacity: 0;
    }
  }
  .promoGridHome {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .promoImgHome {
    width: 42vw;
  }
  .catImgHome {
    width: 42vw;
  }
  .catHome{
    width: 96%;
    margin-top: 50px;
    margin-bottom: 50px;
    scrollbar-width: auto;
    scroll-behavior: smooth;
  }
  .images-zoom-container-home {
    width: 42vw;
    height: auto;
  }
  .images-zoom-container-home {
    transition: transform 0.5s;
  }
  .images-zoom-container-home:hover {
    transform: scale(1.04);
  }
}
