.navbarCollection{
  align-items: center;
  width: 100vw;
  font-family: 'Exo';
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
}
.navbarCollection>a{
  text-decoration: none;
  color: black;
  transition: all 0.6s;
}
.navbarCollection>a:hover{
  text-decoration: none;
  color: var(--thirdColor);
}
.categoryTitle.redText{
    color: var(--thirdColor);
}
.categoryTitle {
    font-size: 16px;
    font-family: 'Poppins';
    text-transform: uppercase;
    text-decoration: none;
    color: black;
    text-align: left;
  }

  .subfamilyItem {
    display: block; 
    text-transform: uppercase;
    font-size: 13px;
    padding-top: 5px;
    color: black;
    margin-left: 30px;
    font-family: 'Poppins';
    text-decoration: none; /* Retirez le soulignement par défaut */
}

.subfamilyItem.select {
    font-weight: 700;
}


  @media screen and (max-width:1200px) {
    .navbarCollection{
      align-items: center;
      width: 80vw;
      font-family: 'Exo';
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: none;
      
    }
    .navbarCollection>a{
      text-decoration: none;
      color: black;
      transition: all 0.6s;
    }
    .navbarCollection>a:hover{
      text-decoration: none;
      color: var(--thirdColor);
    }
    .categoryTitle.redText{
        color: var(--thirdColor);
    }
    .categoryTitle {
        font-size: 14px;
        font-family: 'Poppins';
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        text-align: left;
      }
    
      .subfamilyItem {
        display: block; 
        text-transform: uppercase;
        font-size: 12px;
        padding-top: 5px;
        color: black;
        margin-left: 30px;
        font-family: 'Poppins';
        text-decoration: none; /* Retirez le soulignement par défaut */
    }
    
    .subfamilyItem.select {
        font-weight: 700;
    }
    
  }
  @media screen and (max-width:700px) {
    .navbarCollection{
      align-items: center;
      width: auto;
      font-family: 'Exo';
      font-size: 12px;
      text-transform: uppercase;
      text-decoration: none;
      
    }
    .navbarCollection>a{
      text-decoration: none;
      color: black;
      transition: all 0.6s;
    }
    .navbarCollection>a:hover{
      text-decoration: none;
      color: var(--thirdColor);
    }
    .categoryTitle.redText{
        color: var(--thirdColor);
    }
    .categoryTitle {
        font-size: 12px;
        font-family: 'Poppins';
        text-transform: uppercase;
        text-decoration: none;
        color: black;
        text-align: left;
        white-space: nowrap;
        margin-left: -10px;
      }
    
      .subfamilyItem {
        display: block; 
        text-transform: uppercase;
        font-size: 10px;
        padding-top: 5px;
        color: black;
        margin-left: 5px;
        font-family: 'Poppins';
        text-decoration: none;
    }
    
    .subfamilyItem.select {
        font-weight: 700;
    }
    .subfamilyItemV {
 
        white-space: nowrap;       
    overflow: hidden;        
    text-overflow: ellipsis;
        text-transform: uppercase;
        font-size: 10px;
        padding-top: 0px;
        color: black;
        margin-left: -20px;
        border: 0.5px solid rgba(128, 128, 128, 0.308);
        padding: 2px;
        font-family: 'Poppins';
        text-decoration: none;
  }
  
  .subfamilyItemV.select {
      font-weight: 700;
  }
  }
  