.promoGrid{
  width: 90vw;
  margin-left: 5vw;
    margin-top: 20px;
    display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  }
  .promoImg{
    width: 300px;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
  }
  .promoContainer {
    position: relative;
  }
  
  .promoButton {
    position: absolute;
    right: 0;
    bottom: 0;
    font-family: 'EXO';
    font-size: 12px;
  }
  .images-zoom-container {
    width: 350px;
    height: auto;
    margin-bottom: 50px;
  }
  .images-zoom-container {
    transition: transform 0.5s;
  }
  .images-zoom-container:hover {
    transform: scale(1.04);
  }

  @media screen and (max-width: 1200px) {
    .promoGrid{
      margin-top: 20px;
      
    }
    .promoImg{
      width: 300px;
      height: auto;
      border-radius: 4px;
      cursor: pointer;
    }
    .promoContainer {
      position: relative;
    }
    
    .promoButton {
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: 'EXO';
      font-size: 12px;
    }
    .images-zoom-container {
      width: 300px;
      height: auto;
    }
  }
  @media screen and (max-width: 700px) {
    .promoGrid{
      margin-top: 20px;
    
    }
    .promoImg{
      width: 300px;
      height: auto;
      border-radius: 4px;
      cursor: pointer;
    }
    .promoContainer {
      position: relative;
    }
    
    .promoButton {
      position: absolute;
      right: 0;
      bottom: 0;
      font-family: 'EXO';
      font-size: 12px;
    }
    .images-zoom-container {
      width: 300px;
      height: auto;
    }
  }