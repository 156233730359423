
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    background-color: transparent;
    border: none;
    outline: none;
  }
  
  .custom-prev {
    left: 0;
  }
  
  .custom-next {
    right: -10px;
  }
