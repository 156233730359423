.flashHom {
  width: 100%;
}

.venteflashbig {
  width: 100%;
  display: flex;
  flex-direction: row; /* Maintenir les éléments en ligne */
  align-items: center;
  justify-content: space-around;
  position: relative;
  cursor: pointer;
}

.flashImgHom {
  width: 22vw ;/* Vous pouvez ajuster la taille selon vos besoins */
  border-radius: 4px;
  margin-right: 10px; /* Espacement entre les images */
  animation: pulse 1.8s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.95);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 20px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

/* Media queries si besoin */
@media only screen and (max-width: 1200px) {
  .flashContainer {
      flex-direction: row;
  }
}

@media only screen and (max-width: 700px) {
  .flashHom {
    width: auto;
    margin-top: 10px;
  }
  .flashCarousel{
    width: auto;
   
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
    scroll-behavior: smooth;
  }
  .carouselDivFlash {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
  }
  .flashImgHom1 {
    width: 40vw ;/* Vous pouvez ajuster la taille selon vos besoins */
    border-radius: 2px;
    margin-left: 2px;
    margin-right: 5px;
    animation: pulse 1.8s infinite;
  }
  .flashImgHom1:last-child {
    width: 40vw ;/* Vous pouvez ajuster la taille selon vos besoins */
    border-radius: 2px;
    margin-left: 2px;
    animation: pulse 1.8s infinite;
  }

}
