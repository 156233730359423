.productmarquePages {
    margin-top: 50px;
    margin-bottom: 50px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .triDiv2{
    position: absolute;
    right: 100px;
    top: 25px;
  }
  .pagination {
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: -60px;
    margin-bottom: 60px;
    z-index: 111;
  }
  @media screen and (max-width: 1200px) {
    .productmarquePages {
        margin-top: 50px;
        margin-bottom: 50px;
        height: auto;
      
      }
      
     
      
      .pagination {
        height: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        bottom: -60px;
        margin-bottom: 60px;
        z-index: 111;
      }
      .triDiv2{
        position: absolute;
        right: 40px;
        top: 20px;
      }
  }
  @media screen and (max-width: 700px) {
    .productmarquePages {
        margin-top: 50px;
        margin-bottom: 50px;
        height: auto;
     
      }
      
     
      
     
      .triDiv2{
        position: absolute;
        right: 5px;
        top: 80px;
      }
  }
  