.carousselDivHome {
  width: 100vw;
  margin: 15px 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: rgb(237, 237, 237);
}
.banPromoPic {
  width: 44vw;
  margin-left: 5.5vw;
  border-radius: 4px;
  margin-bottom: 1vw;
}
.banPromoPic2 {
  width: 43.6vw;
  border-radius: 4px;
  margin-left: 1.5vw;
  margin-bottom: 1vw;
}
.carouselDivV {
  display: flex;
  flex-direction: column;
}
.carouselDivH {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .carousselDivHome {
    width: 100vw;
    margin: 15px 0px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: rgb(221, 221, 221);
  }
  .banPromoPic {
    width: 46.4vw;
    margin-left: 3vw;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .banPromoPic2 {
    width: 46.2vw;
    border-radius: 4px;
    margin-left: 10px;
    margin-bottom: 5px;
  }

  .carouselDivH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 700px) {
  .carousselDivHome {
    width: auto;
    margin: 15px 0px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: auto;
    scroll-behavior: smooth;
  }
  .banPromoPic {
    width: 72vw;
    margin-left: 0vw;
    border-radius: 4px;
    margin-bottom: 3px;
  }
  .banPromoPic2 {
    width: 72.6vw;
    border-radius: 4px;
    margin-left: 6px;
    margin-bottom: 3px;
  }

  .carouselDivH {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .carouselDivV {
    display: flex;
    flex-direction: column;
  }
}
