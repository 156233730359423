.hidden {
    display: none; 
  }
.articleDetailePlus{
  position: absolute;
  bottom: -5px;
  left: 0;
}
  .promoLabel {
    position: absolute;
    text-transform: uppercase;
    font-family: 'Exo';
    letter-spacing: 1px;
    top: 1px;
    left: 1px;
    color: #ffeb3b;
    background-color: var(--thirdColor);
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #ffeb3b;
    z-index: 9900;
    animation: pulse 1.8s infinite;
  }
  .flashLabel{
    position: absolute;
    text-transform: uppercase;
    font-family: 'Exo';
    letter-spacing: 1px;
    top: 1px;
    left: 1px;
    background-color: #ffeb3b;
    color: var(--thirdColor);
    padding: 5px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #ffeb3b;
    z-index: 9900;
    animation: pulse 1.8s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
  
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 20px rgba(255, 177, 66, 0);
    }
  
    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
    }
  }
  .card {
    position: relative;
  }
  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
.cardMedia{
  width: 220px;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaDialog:hover {
  transform: scale(1.1);
}
.articleDetaile{
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 5px;
}
  @media screen and (max-width:1200px) {
    .promoLabel {
      
      padding: 2px;
      font-size: 10px;
      font-weight: 600;
      border-radius: 2px;
      border: 1px solid #ffeb3b;
      z-index: 9900;
      animation: pulse 1.8s infinite;
    }
    .flashLabel {
      
      padding: 2px;
      font-size: 10px;
      font-weight: 600;
      border-radius: 2px;
      border: 1px solid #ffeb3b;
      z-index: 9900;
      animation: pulse 1.8s infinite;
    }
    .hidden {
      display: none; 
    }
  
    .cardMediaLink{
      text-decoration: none;
      text-align: center;
    }
  .cardMedia{
    width: 70%;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMedia:hover {
    transform: scale(1.1);
    color: rgba(0, 0, 0, 0.082);
  }
  
  .cardMediaDialog{
    padding: 50px;
    width: 80%;
    height: auto;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
  }
  
  .cardMediaDialog:hover {
    transform: scale(1.1);
  }
  .articleDetaile{
    font-family: 'Poppins';
    text-transform: uppercase;
    font-size: 13px;
    margin-top: 5px;
  }
}  
@media screen and (max-width:700px) {
  .promoLabel {
      
    padding: 2px;
    font-size: 9px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #ffeb3b;
    z-index: 9900;
    animation: pulse 1.8s infinite;
  }
  .flashLabel {
      
    padding: 2px;
    font-size: 9px;
    font-weight: 600;
    border-radius: 2px;
    border: 1px solid #ffeb3b;
    z-index: 9900;
    animation: pulse 1.8s infinite;
  }
  .hidden {
    display: none; 
  }

  .cardMediaLink{
    text-decoration: none;
    text-align: center;
  }
.cardMedia{
  width: 70%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMedia:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.082);
}

.cardMediaDialog{
  padding: 50px;
  width: 80%;
  height: auto;
  transition: transform 0.4s ease-in-out;
  cursor: pointer;
}

.cardMediaDialog:hover {
  transform: scale(1.1);
}
.articleDetaile{
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 11px;
  margin-top: 5px;
}
}  