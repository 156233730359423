.header {
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 1px 8px 1px rgba(104, 104, 104, 0.75);
  z-index: 9999999999999;
  animation: load 1s ease-in-out; 
  background-color: white;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.navbarTop {
  width: 100vw;
  position: absolute;
  top: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 999999;
  background-color:#283943;
  color: #F0E7D8;
}

.navbarTop > p {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 2px;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: 'Poppins';
  cursor: pointer;
  color: #FFF;
  z-index: 9999999999999;
}
.wave-text {
  display: inline;
  font-size: 1.2em;
  font-weight: bold;
  font-family: 'Exo';
}

.wave-text span {
  display: inline-block;
  animation: wave-animation 3s ease-in-out infinite;
  margin-left: 5px;
  font-family: 'Exo';
}

@keyframes wave-animation {
  0%, 100% {
   opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.wave-text span:nth-child(odd) {
  animation-delay: 0.1s;
}

.wave-text span:nth-child(even) {
  animation-delay: 0.2s;
}

.logoContainer {
  position: relative;
  width: 100%;
  height: 100px; /* Ajustez selon la hauteur des logos */
  overflow: hidden;
}




.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-bottom: 4px;
  height: 80px;
}

.navbarBottom {
  background-color: #F99984;
}

.userDiv {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70vw;
}

.searchListDiv {
  display: flex;
  flex-direction: column;
  width: 400px;
}

.searchList {
  width: 400px;
}

.logo {
  width: 350px;
  height: auto;
  margin: 20px 40px;
  animation: pulse 4s linear;
  transform: scale(1.15);
}

.DialogDiv {
  background-color: var(--firstColor);
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: 'Exo';
}

.iconImg {
  width: 60px;
  cursor: pointer;
  transition: transform 0.6s;
}

.iconImg:hover {
  transform: translateY(-5px);
}
.navbarIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 60%;
  margin-left: 20%;
}

.iconContainer > a {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
}

.iconContainer > a > span {
  font-family: 'Poppins';
  font-size: 12px;
  text-transform: uppercase;
}
.bonjourHead{
  margin-left: 5px;
  font-size: 14px;
  text-transform: capitalize;
  font-family: 'Poppins';
}
@keyframes pulse {
  0% { transform: scale(1); opacity: 0; }
  50% { transform: scale(1.1); opacity: 0.5; }
  100% { transform: scale(1.15); opacity: 1; }
}

.linkMenu {
  position: absolute;
  top: -15px;
  right: 0;
  background-color: white;
  transform: translateX(100%);
  transition: transform 0.3s ease;
  z-index: 999999;
}

.linkMenu.show {
  transform: translateX(0);
}

.linkItems {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .searchListDiv {
    width: 250px;
  }
  .searchList {
    width: 250px;
  }
  .logo {
    width: 300px;
  }
  .navbarIcon {
    width: 90%;
    margin-left: 5%;
  }
  .userDiv {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 70vw;
  }
  .userDivData{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: absolute;
    top: 5px;
  }
  .userDivNoConnect{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media only screen and (max-width: 700px) {

  .logoDiv {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .navbar {
    flex-direction: column;
    height: auto;
  }
  .searchListDiv {
    width: 230px;
    margin-left: -20px;
  }
  .searchList {
    width: 230px;
  }
  .logo {
    width: 240px;
    margin: 20px 10px;
  }
  .navbarIcon {
    display: none;
  }
  .linkMenu {
    height: 120vh;
    width: 100%;
    overflow: hidden;
    overflow-y: hidden;
    position: fixed;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    background-color:  var(--firstColor);
  }

  .iconImg {
    width: 50px;
    margin: 0px 4px;
    padding-right: 25%;
  }
  .iconImg.home {
    width: 40px;
    margin: 0px 4px;
    padding-right: 25%;
  }
  .linkMenu li {
    margin-top: 0px;
    list-style: none;
    margin-left: 0vw
  }
  .iconContainer {
    margin-top: 1px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .iconContainer > a {
    padding: 2px 4px;
    flex-direction: row;
    align-items: center;
    justify-content:flex-start;
    height: 7vh;
    padding-left: 25%;
    width: 100vw;
    margin-left: -25%;
    background-color:white;
    color: #F99984;
  }
  .iconContainer > a > span {
    font-family: 'Poppins';
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-left: -25px;
  }
  .socialMediaIcon{
    width: 60vw;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 0px;
    height: 7vh;
  }
  .socialMediaIcon>h3{
    text-transform: uppercase;
    font-family: 'Exo';
    font-size: 14px;
    color:#283943 ;
  }
  .wave-text>span {
    display: inline;
    font-size: 9px;
    font-weight: bold;
    margin-left: 3px;
  }
}
