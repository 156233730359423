
.navbarCollection.details{
  margin-left: 2.5%;
  width: 95%;
}
.loader.details{
  margin-left: 40%;
  margin-top: 10%;
}
.custom-carousel{
    width: 400px;
    height: auto;
    border-radius: 4px;
    cursor: zoom-in;
}
.productcardDetails{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
.productDetails {
  width: 70%;
  margin-left: 15%;
  height: auto;
  margin-bottom: 15vh;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.morecardDetailP{
    margin-left: 50px;
    width: 400px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}
.cardDetails{
    border-radius: 2px;
  z-index: 9999999999999999999999999999;
}
.morecardDetailP>h2{
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
.morecardDetailP>h4{
   
    width: 100%;
    font-size: 18px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    color: var(--thirdColor);
}
.morecardDetailP>p{
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 5px;
    font-family: "Poppins";
    color: black;
}
.bestProductDiv{
    padding: 10px;
}
.bestProductDiv>h2{
    width: 100%;
    font-size: 20px;
    margin-left: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
.custom-carousel .thumbs-wrapper {
    margin-top: 10px;
  }
  
  .custom-carousel .thumbs-wrapper .thumb {
    width: 20px; 
    height: auto; 
    margin-right: 5px; 
  }
  
  .custom-carousel .thumbs-wrapper .thumb.selected {
    border: 2px solid var(--secondColor); 
  }
  .DialogDivRate{
    width: 30vw;
    height: 350px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--firstColor);
    font-family: 'Poppins';
    overflow: hidden;
  }
  .avis{
    font-family: 'Exo';
    margin-top: 2px;
    text-align: center;
  }
  .profitDetail{
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px rgba(0, 0, 0, 0.089) solid;
    display: flex;
    flex-direction: column;
  }
  .profitDetail .info{
    font-family: 'Poppins';
    color: var(--thirdColor);
  }
  .profitDetailspan{
    font-family: 'Poppins';
    font-size: 14px;
  }
  .morecardDetailPrice{
    color: rgb(53, 53, 53);
    font-size: 12px;
  }
  .colorboisDiv{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
  .boisColor{
    width: 40px;
    margin-left: 5px;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 4px;
    transition: 0.8s all;
  }
  .boisColor.selected{
    width: 50px;
    border: 4px solid black;
    cursor: pointer;
  }
  .boisColor:hover{
    width: 100px;
    border: 1px solid black;
    cursor: pointer;
  }
  .morecardDetailColor{
    font-size: 14px;
    font-family: 'Exo';
    text-transform: uppercase;
  }
@media screen and (max-width: 1200px) {
  .productDetails {
    width: 80%;
    margin-left: 10%;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
    .custom-carousel{
        width: 25vw;
        height: auto;
    }
    .productcardDetails{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }
    .morecardDetailP{
        margin-left: 25px;
        margin-right: 30px;
        width: 35vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    
    }
    .morecardDetailP>h2{
        width: 100%;
        font-size: 14px;
        padding-right: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetailP>h4{
       
        width: 100%;
        font-size: 15px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        color: var(--thirdColor);
    }
    .morecardDetailP>p{
       
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 28px;
        padding: 5px;
        font-family: "Poppins";
        color: black;
    }
    .bestProductDiv{
        padding: 10px;
    }
    .bestProductDiv>h2{
        width: 100%;
        font-size: 16px;
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .custom-carousel .thumbs-wrapper {
        margin-left: -50px !important;
        margin-top: 10px;
        width: 300px;
      }
      
      .custom-carousel .thumbs-wrapper .thumb {
        width: 65px !important; 
        height: 65px; 
        margin-right: 5px; 
      }
      
      .custom-carousel .thumbs-wrapper .thumb.selected {
       margin-left: 10px;
        border: 2px solid var(--secondColor); 
      }
      .DialogDivRate{
        width: 60vw;
        height: 350px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        background-color: var(--firstColor);
        font-family: 'Poppins';
        overflow: hidden;
      }
      .avis{
        font-family: 'Exo';
        margin-top: 2px;
        text-align: center;
      }
      .profitDetail .info{
        font-family: 'Poppins';
        color: var(--thirdColor);
      }
      .profitDetailspan{
        font-family: 'Poppins';
        font-size: 13px;
      }
      .morecardDetailPrice{
        color: rgb(53, 53, 53);
        font-size: 11px;
      }
  }
  @media screen and (max-width: 700px) {
 .pathTitle{
  text-align: center;
  margin-top: 25px;
 }
 .navbarCollection.details{
  margin-left: 2.5%;
  width: 95%;
}
    .productDetails {
      width: 100%;
      margin-left: 0;
      height: auto;
      margin-bottom: 50px;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      overflow-x: hidden;
    }
    .productContainDetail{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: auto;
        margin-left: 0%;
    }
    .custom-carousel{
        width: 65vw;
        height: auto;
    }
    .productcardDetails{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .morecardDetailP{
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        z-index: 9999;
        margin-left: 10vw;
    }
    .morecardDetailP>h2{
        width: 100%;
        font-size: 11px;
        padding-right: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetailP>h4{
       
        width: 100%;
        font-size: 13px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        color: var(--thirdColor);
    }
    .morecardDetailP>p{
       
        width: 100%;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 24px;
        padding: 5px;
        font-family: "Poppins";
        color: black;
    }
    .bestProductDiv{
        padding: 10px;
    }
    .bestProductDiv>h2{
        width: 100%;
        font-size: 12px;
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .custom-carousel .thumbs-wrapper {
        margin-left: -60px !important;
        margin-top: 10px;
        width: 300px;
      }
      
      .custom-carousel .thumbs-wrapper .thumb {
        width: 55px !important; 
        height: 55px; 
        margin-right: 1px; 
      }
      
      .custom-carousel .thumbs-wrapper .thumb.selected {
       
        border: 1px solid var(--secondColor); 
      }
      .DialogDivRate{
        width: 90vw;
        height: 400px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: var(--firstColor);
        font-family: 'Poppins';
        overflow: hidden;
      }
      .avis{
        font-family: 'Exo';
        margin-top: 2px;
        font-size: 12px;
        text-align: center;
      }
      .profitDetailspan{
        font-family: 'Poppins';
        text-transform: capitalize;
        font-size: 12px;
      }
      .morecardDetailPrice{
        color: rgb(53, 53, 53);
        font-size: 9px;
      }
      .boisColor:hover{
        width: 80px;
        border: 1px solid black;
        cursor: pointer;
      }
      .morecardDetailColor{
        font-size: 12px;
        font-family: 'Exo';
      }
  }
  