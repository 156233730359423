.cart{
    display: flex;
    width: 100vw;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.panier{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(107, 107, 107, 0.09);
    border-radius: 4px;
    width: 60vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items:flex-start;
    font-family: 'Exo';
    letter-spacing: 2px;
    padding: 10px;
}
.panierVide{
    width: 60%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(107, 107, 107, 0.09);
    border-radius: 4px;
    height: auto;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    padding: 50px 0px;
    font-family: 'Exo';
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
}
.totalDiv{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(107, 107, 107, 0.09);
    border-radius: 4px;
    width: 20vw;
    height: min-content;
   margin-left: 5vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items:flex-start;
    padding: 10px;
    font-family: 'Poppins';
    text-transform: uppercase;
}
.totalDiv>h5{
    font-size: 14px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.11);
    width: 100%;
    padding-bottom: 10px;
}
.totalDiv>p{
    font-size: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.artcileDetails{
    display: flex;
    flex-direction: column;
}
.allDetail{
    width: 100%;
}
.pictureName{
    display: flex;
    flex-direction: row;

}
.nameBrand{
    display: flex;
    flex-direction: column;
    
}
.nameBrand>span{
    font-size: 12px;
    font-family: 'Exo';
    text-transform: uppercase;
    margin-left: 20px;
}
.priceDiv{
   font-family: 'Poppins';
   font-size: 14px;
}
li{list-style: none;}
.prodPic{
    margin-right: 10px;
    width: 100px;
    height: auto;
    object-fit: contain;
}

.allDetail{
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: #aaa 0.5px solid;
}
.prodDetail{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-left: 25px;
    
}
.panierTitle{
    font-family: 'Poppins';
    font-size: 18px;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.815);
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.199);
    width: 100%;
}
.quantity{
    display: flex;
    flex-direction: row;
    font-size: 16px;
    font-family: 'Poppins';
}
.numberArticle{
    margin: 0px 10px;
    color: rgba(0, 0, 0, 0.815);
}

#commande{
    line-height: 30px;
    font-size: 14px;
    font-family: 'Geo',sans-serif;
    letter-spacing: 2px;
    font-weight: 600;
    color:#090909;
}
.total{
    width: 50vw;
    font-size: 20px;
    margin-left: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    font-family: 'Nexa',sans-serif;
    letter-spacing: 2px;
    font-weight: 900;
    margin-top: -10px;
}
.tarif{
    margin-top: 20px;
    margin-bottom: 40px;
    color: #48A1A4;
    text-align: center;
    font-family: 'Code';
    font-weight: 900;
}
.validForm{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-family: 'Poppins';
}
.input{
    font-family: 'Poppins';
    font-size: 12px;
}

.livraisonDomicile{
    width: 100%;
    text-align: center;
    background-color:ghostwhite;
    font-size: 12px;
    border-radius: 4px;
    margin-bottom: 10px;
}
.totalTTC{
    font-weight: 700;
}
  @media only screen and (max-width: 1200px) {
    .cart{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .panier{
        border-radius: 4px;
        width: 80vw;
        height: min-content;
        margin-top: 5vh;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
        align-items:flex-start;
        font-family: 'Exo';
        letter-spacing: 2px;
        padding: 10px;
    }
    .panierVide{
        border-radius: 4px;
        width: 80vw;
        height: auto;
        margin-top: 5vh;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        font-family: 'Exo';
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 14px;
    }
    .totalDiv{
        border-radius: 4px;
        width: 80vw;
        height: min-content;
       margin-left: 0vw;
        margin-top: 20px;
        margin-bottom: 5vh;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items:flex-start;
        padding: 10px;
        font-family: 'Poppins';
        text-transform: uppercase;
    }
    .totalDiv>h5{
        font-size: 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
        width: 100%;
        padding-bottom: 10px;
    }
    .totalDiv>p{
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .artcileDetails{
        display: flex;
        flex-direction: column;
    }
    .allDetail{
        width: 100%;
    }
    .pictureName{
        display: flex;
        flex-direction: row;
    
    }
    .nameBrand{
        display: flex;
        flex-direction: column;
        
    }
    .nameBrand>span{
        font-size: 12px;
        font-family: 'Exo';
        text-transform: uppercase;
        margin-left: 20px;
    }
    .priceDiv{
       font-family: 'Poppins';
       font-size: 14px;
    }
    li{list-style: none;}
    .prodPic{
        margin-right: 10px;
        width: 100px;
        height: auto;
        object-fit: contain;
    }
    
    .allDetail{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border-bottom: #aaa 0.5px solid;
    }
    .prodDetail{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 25px;
        
    }
    .panierTitle{
        font-family: 'Poppins';
        font-size: 18px;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.815);
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.199);
        width: 100%;
    }
    .quantity{
        display: flex;
        flex-direction: row;
        font-size: 16px;
        font-family: 'Poppins';
    }
    .numberArticle{
        margin: 0px 10px;
        color: rgba(0, 0, 0, 0.815);
    }
    
    #commande{
        line-height: 30px;
        font-size: 14px;
        font-family: 'Geo',sans-serif;
        letter-spacing: 2px;
        font-weight: 600;
        color:#090909;
    }
    
    .tarif{
        margin-top: 20px;
        margin-bottom: 40px;
        color: #48A1A4;
        text-align: center;
        font-family: 'Code';
        font-weight: 900;
    }
    .validForm{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-family: 'Poppins';
    }
    .input{
        font-family: 'Poppins';
        font-size: 14px;
    }
    
}
@media only screen and (max-width: 700px) {
  
    .panier{
        border-radius: 4px;
        width: 90vw;
        height: min-content;
        margin-top: 5vh;
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content:space-evenly;
        align-items:flex-start;
        font-family: 'Exo';
        letter-spacing: 2px;
        padding: 10px;
    }
    .panierVide{
        border-radius: 4px;
        width: 90vw;
        height: auto;
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        text-align: center;
        justify-content: space-between;
        padding: 20px 0px;
        font-family: 'Exo';
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 10px;
    }
    .totalDiv{
        border-radius: 4px;
        width: 90vw;
        height: min-content;
        margin-top: 20px;
        margin-bottom: 5vh;
        display: flex;
        flex-direction: column;
        justify-content:space-between;
        align-items:flex-start;
        padding: 10px;
        font-family: 'Poppins';
        text-transform: uppercase;
    }
    .totalDiv>h5{
        font-size: 14px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.11);
        width: 100%;
        padding-bottom: 10px;
    }
    .totalDiv>p{
        font-size: 14px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
    }
    .artcileDetails{
        display: flex;
        flex-direction: column;
    }
    .allDetail{
        width: 100%;
    }
    .pictureName{
        display: flex;
        flex-direction: row;
    
    }
    .nameBrand{
        display: flex;
        flex-direction: column;
        
    }
    .nameBrand>span{
        font-size: 10px;
        font-family: 'Exo';
        text-transform: uppercase;
        margin-left: 10px;
    }
    .priceDiv{
       font-family: 'Poppins';
       font-size: 12px;
    }
    li{list-style: none;}
    .prodPic{
        margin-right: 10px;
        margin-left: -30px;
        width: 80px;
        height: auto;
        object-fit: contain;
        
    }
    
    .allDetail{
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        border-bottom: #aaa 0.5px solid;
    }
    .prodDetail{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-left: 25px;
        
    }
    .panierTitle{
        font-family: 'Poppins';
        font-size: 16px;
        letter-spacing: 2px;
        color: rgba(0, 0, 0, 0.815);
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.199);
        width: 100%;
    }
    .quantity{
        display: flex;
        flex-direction: row;
        font-size: 14px;
        font-family: 'Poppins';
    }
    .numberArticle{
        margin: 0px 10px;
        color: rgba(0, 0, 0, 0.815);
    }
    
    #commande{
        line-height: 30px;
        font-size: 14px;
        font-family: 'Geo',sans-serif;
        letter-spacing: 2px;
        font-weight: 600;
        color:#090909;
    }
 
    .tarif{
        margin-top: 20px;
        margin-bottom: 40px;
        color: #48A1A4;
        text-align: center;
        font-family: 'Code';
        font-weight: 900;
    }
    .validForm{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        font-family: 'Poppins';
    }
    .input{
        font-family: 'Poppins';
        font-size: 14px;
    }
    
}