.products100 {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
}

.product100 {
  width: 100%;
  height: auto;
  margin-bottom: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.product100Contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  margin-left: 2.5%;
  height: auto;
  margin-top: 60px;
}

.vente100Img{
  width: 100vw;
  height: auto;
  margin-top: -55px;
 
}


@media screen and (max-width: 1200px) {
  .products100 {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  
  .product100 {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
.product100Contain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98%;
  margin-left: 1%;
  height: auto;
  margin-top: 20px;
}

.vente100Img{
  width: 96vw;
  margin-left: 2vw;
  height: auto;
  margin-top: -20px;
 
}

}
@media screen and (max-width: 700px) {
  .products100 {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
  }
  
  .product100 {
    width: 100%;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
.productMarqueContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: auto;
  margin-top: -60px;
}

.vente100Img{
  width: 98vw;
  margin-left: 1vw;
  height: auto;
  margin-top: -5px;
}
}
