.categorieProduct{
  display: flex;
  flex-direction: row;
}
.carousselDivHomeProduct {
  width: 100vw;
  margin: 15px 0px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.banPromoPicProduct {
  width: 21vw;
  border-radius: 4px;
}
.banPromoPic2product {
  width: 20.5vw;
  border-radius: 4px;
  margin-left: 1vw;
  margin-bottom: 5px;
}
.carouselDivVProduct {
  display: flex;
  flex-direction: column;
}
.carouselDivHProduct {
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .categorieProduct{
    display: flex;
    flex-direction: row;
  }
  .carousselDivHomeProduct {
    width: 100vw;
    margin: 15px 0px;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .banPromoPicProduct {
    width: 21vw;
    border-radius: 4px;
  }
  .banPromoPic2product {
    width: 20.5vw;
    border-radius: 4px;
    margin-left: 1vw;
    margin-bottom: 5px;
  }
  .carouselDivVProduct {
    display: flex;
    flex-direction: column;
  }
  .carouselDivHProduct {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
@media only screen and (max-width: 700px) {
  .categorieProduct{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .carousselDivHomeProduct {
    width: 100vw;
    margin: 15px 0px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .banPromoPicProduct {
    width: 80%;
    margin-left: 10%;
    border-radius: 4px;
  }
  .banPromoPic2product {
    width: 80%;
    margin-left: 10%;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .carouselDivVProduct {
    display: flex;
    flex-direction: column;
      justify-content: center;
      align-items: center;
  }
  .carouselDivHProduct {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
