.promos {
    width: 100%;
    margin-left: 5%;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }

  .productContainpromoDetail{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 10px;
}
.custom-carousel{
    width: 350px;
    height: auto;
    border-radius: 4px;
}
.productcardDetails{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
}
.morecardDetail{
    margin-left: 150px;
    width: 400px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

}
.cardDetails{
    border-radius: 2px;
}
.morecardDetail>h2{
    width: 100%;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
.morecardDetail>h4{
   
    width: 100%;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    color: var(--thirdColor);
}
.eco{
    text-align: left;
    font-size: 14px;
    margin-left: 5px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 5px;
    border-radius: 4px;
    font-family: "Exo";
    color: var(--firstColor);
    background-color: var(--thirdColor);
}
.morecardDetail>h3{
   
    width: 100%;
    font-size: 14px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
}
.morecardDetail>p{
   
    width: 100%;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 28px;
    padding: 5px;
    font-family: "Poppins";
    color: black;
}
.bestProductDiv{
    padding: 10px;
}
.bestProductDiv>h2{
    width: 100%;
    font-size: 20px;
    margin-left: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
.custom-carousel .thumbs-wrapper {
    margin-top: 10px;
    margin-left: -25px;
    margin-right: -100px;
  }
  
  .custom-carousel .thumbs-wrapper .thumb {
    width: 20px; 
    height: auto; 
    margin-right: 5px; 
  }
  
  .custom-carousel .thumbs-wrapper .thumb.selected {
    border: 2px solid var(--secondColor); 
  }
  .bestProductDiv>h3{
    width: 100%;
    font-size: 20px;
    margin-left: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Exo";
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
}
@media screen and (max-width: 1200px) {
    .promos {
        width: 90%;
        margin-left: 5%;
        height: auto;
        margin-bottom: 15vh;
        position: relative;
        display: flex;
        flex-direction: row;
        margin-top: 25px;
        animation: load 1s ease-in-out;
        overflow-x: hidden;
      }
    .custom-carousel{
        width: 30vw;
        height: auto;
    }
    .productcardDetails{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
    }
    .morecardDetail{
        margin-left: 20vw;
        width: 35vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    
    }
    .morecardDetail>h2{
        width: 100%;
        font-size: 16px;
        padding-right: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetail>h4{
       
        width: 100%;
        font-size: 14px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        color: var(--thirdColor);
    }
    .eco{
        text-align: left;
        font-size: 12px;
        margin-left: 2px;
        font-weight: 800;
        letter-spacing: 1px;
        padding: 5px;
        border-radius: 4px;
    }
    .morecardDetail>h3{
       
        width: 100%;
        font-size: 12px;
    }
    .morecardDetail>p{
       
        width: 100%;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 28px;
        padding: 5px;
        font-family: "Poppins";
        color: black;
    }
    .bestProductDiv{
        padding: 10px;
    }
    .bestProductDiv>h2{
        width: 100%;
        font-size: 16px;
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .custom-carousel .thumbs-wrapper {
        margin-left: -50px !important;
        margin-top: 10px;
        width: 300px;
      }
      
      .custom-carousel .thumbs-wrapper .thumb {
        width: 65px !important; 
        height: 65px; 
        margin-right: 5px; 
      }
      
      .custom-carousel .thumbs-wrapper .thumb.selected {
       
        border: 2px solid var(--secondColor); 
      }
      .bestProductDiv>h3{
        width: 100%;
        font-size: 16px;
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
  }
  @media screen and (max-width: 700px) {
    .productContainpromoDetail{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        height: auto;
        margin-left: 10px;
    }
    .custom-carousel{
        width: 65vw;
        height: auto;
    }
    .productcardDetails{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    .morecardDetail{
        margin-left: 15px;
        width: 75vw;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        z-index: 9999;
    }
    .morecardDetail>h2{
        width: 100%;
        font-size: 11px;
        padding-right: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .morecardDetail>h4{
       
        width: 100%;
        margin-left: 20px;
        font-size: 12px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        color: var(--thirdColor);
    }
    .eco{
        text-align: left;
        font-size: 11px;
        margin-left: 5px;
        font-weight: 800;
        letter-spacing: 1px;
        padding: 3px;
        border-radius: 4px;
    }
    .morecardDetail>h3{
       
        width: 100%;
        margin-left: 20px;
        font-size: 11px;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
    }
    .morecardDetail>p{
       
        width: 100%;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 1px;
        line-height: 24px;
        padding: 5px;
        font-family: "Poppins";
        color: black;
    }
    .bestProductDiv{
        padding: 10px;
    }
    .bestProductDiv>h2{
        width: 100%;
        font-size: 14px;
        margin-left: 20px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
    .custom-carousel .thumbs-wrapper {
        margin-left: -60px !important;
        margin-top: 10px;
        width: 300px;
      }
      
      .custom-carousel .thumbs-wrapper .thumb {
        width: 55px !important; 
        height: 55px; 
        margin-right: 1px; 
      }
      
      .custom-carousel .thumbs-wrapper .thumb.selected {
       
        border: 1px solid var(--secondColor); 
      }
      .bestProductDiv>h3{
        width: 100%;
        font-size: 12px;
        margin-left: 10px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-family: "Exo";
        border-bottom: 1px solid rgba(0, 0, 0, 0.13);
        color: black;
    }
  }
  