.banniereHome {
  width: 100%;
  margin-bottom: 40px;
}
.banniereHome>div>img{
  margin-left: 0%;
  width: 100%;
}


@media only screen and (max-width: 1200px) {
  .banniereHome {
    width: 125%;
    margin-left: -15%;
  }
  .banniereHome>img{
    width: 130%;
  }
}
@media only screen and (max-width: 700px) {
  .banniereHome {
    width: 120%;
    margin-left: -14%;
    margin-bottom: 30px;
    margin-top: -30px;
  }
  .banniereHome>img{
    width: 125%;
  }
}
