
  .slideNew {
    background-color: #283943;
    width: 100vw;
    margin-left: -5vw;
  }
  @media only screen and (max-width: 1200px) {

    .slideNew {
      background-color: #283943;
      width: 100vw;
      margin-left: -5vw;
    }
  }
  @media only screen and (max-width: 700px) {
   
    .slideNew {
      background-color: #283943;
      width: 100vw;
      margin-left: -2.5vw;
    }
  }
  