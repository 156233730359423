.ourBrand{
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;

}
.ourBrand>h2{
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 3px;
  background-color: #283943;
  color: #F0E7D8;
  width: auto;
}
.ourBrand>p{
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 70vw;
  text-align: center;
}
.refGrid{
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.refImg{
  width: 180px;
  height: auto;
  border-radius: 4px;
  cursor: pointer;
  margin-top: -30px;
}
.image-zoom-container {
  transition: transform 0.4s;
}
.image-zoom-container:hover {
  transform: scale(1.15);
}

@media screen and (max-width: 1200px) {
  .ourBrand{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
  
  }
  .ourBrand>h2{
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 3px;
    background-color: #283943;
    color: #F0E7D8;
  }
  .ourBrand>p{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 90vw;
    text-align: center;
  }
  .refGrid{
    margin-top: 20px;
  }
  .refImg{
    width: 160px;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
  }
  .image-zoom-container {
    transition: transform 0.4s;
  }
  .image-zoom-container:hover {
    transform: scale(1.10);
  }
  
}
@media screen and (max-width: 700px) {
  .ourBrand{
    display: flex;
    flex-direction: column;
    width: 100vw;
    align-items: center;
  
  }
  .ourBrand>h2{
    padding: 4px 8px;
    border-radius: 2px;
    margin-top: 40px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }
  .ourBrand>p{
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0px;
    width: 90vw;
    text-align: center;
  }
  .refGrid{
    width: 95%;
    margin-top: 20px;
  }
  .refImg{
    width: 120px;
    margin-top: -10px;
    margin-bottom: -30px;
    height: auto;
    border-radius: 4px;
    cursor: pointer;
  }

  .image-zoom-container {
    transition: transform 0.4s;
  }
  .image-zoom-container:hover {
    transform: scale(1.15);
  }

}
