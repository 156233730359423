.products {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.nav {
  margin-top: 0px;
  position: relative;
}
.product {
  width: 100%;
  height: auto;
  margin-bottom: 15vh;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  animation: load 1s ease-in-out;
  overflow-x: hidden;
}
.productContain {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;
}

.pathTitle {
  width: 90%;
  margin-left: 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Poppins";
  padding: 4px 20px;
  border-radius: 2px;
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  color: black;
}
@media screen and (max-width: 1200px) {
  .products {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .nav {
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: 100vw;
    height: auto;
    margin-bottom: 50px;
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 25px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 15px;
  }
  
  .pathTitle {
    width: 100%;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Poppins";
    padding: 4px 20px;
    border-radius: 2px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
}
@media screen and (max-width: 700px) {
  .products {
    width: 100%;
    margin-left: 0px;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
    margin-top: 5px;
  }
  .nav {
    width: auto;
    margin-left: 5vw;
    margin-top: 20px;
    position: relative;
  }
  .product {
    width: 100vw;
    height: auto;
    margin-bottom: 15vh;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    animation: load 1s ease-in-out;
    overflow-x: hidden;
  }
  .productContain {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  
  .pathTitle {
    width: 90%;
    margin-left: -10px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: "Poppins";
    padding: 4px 20px;
    border-radius: 2px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    color: black;
  }
}
