.carouselHome{
  width: 85vw;
  margin-left: 2.5vw;
  height: auto;
  margin-top: 10px;
}

  .carouselHome img {
    width: 99%;
    margin-left: 0.5%;
    height: auto;
    object-fit: fill;
    border-radius: 4px;
}
.dotsUl{
  margin: 0;
  position: absolute;
  left: 45%; 
  transform: translateX(-50%); 
  display: none;
}
.slick-dots li button:before {
  color: white !important;
  font-size: 80px  !important;
  content:' '; 
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px; 
  height: 40px; 
  background-color: rgba(255, 255, 255, 0.5); 
  border-radius: 50%; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; 
  color: black;
 
}

.custom-prev {
  left: -55px; 
}

.custom-next {
  right: -55px; 
}

  @media screen and (max-width:1200px) {
    .carouselHome{
      width: 90vw;
      margin-left: 0vw;
      height: auto;
      margin-top: 10px;
    }
    
      .carouselHome img {
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 4px;
    }
   
    .slick-dots li button:before {
      color: white !important;
      font-size: 80px  !important;
      content:' '; 
    }
    .custom-arrow {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 40px; 
      height: 40px; 
      background-color: rgba(255, 255, 255, 0.5); 
      border-radius: 50%; 
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px; 
      color: black;
    }
    
    .custom-prev {
      left: -50px; 
    }
    
    .custom-next {
      right: -50px; 
    }
    
  }
  @media screen and (max-width:700px) {
    .carouselHome{
      height: auto;
      width: 98vw;
      margin-left: -2vw;
      margin-top: 20px;
    }
      .carouselHome img {
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 4px;
    }
    .custom-arrow {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 20px; 
      height: 20px; 
      z-index: 99999;
      background-color: rgba(255, 255, 255, 0.5); 
      border-radius: 50%; 
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px; 
      color: black;
    }
    .dotsUl{
      margin: 0; 
      display: none;
      position: absolute;
      left: 10%; /* Center horizontally */
      bottom: 80px; 
      transform: translateX(-0%); 
    }
    .slick-dots li button:before {
      color: white !important;
      font-size: 80px  !important;
      content:'.'; 
    }
  }